.xp-bar-wrapper {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 200px;
  height: 20px;
}

.xp-bar-container {
  width: 100%;
  height: 100%;
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.xp-bar {
  height: 100%;
  background-color: #00ff00;
  transition: width 0.3s ease;
  position: relative;
}

.xp-text {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-weight: bold;
  line-height: 20px; /* Adjust line height to match the height of the bar */
}

.xp-bar-click-me {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-weight: bold;
  line-height: 20px; /* Adjust line height to match the height of the bar */
  z-index: 1;
}

.popup-message {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 2;
}
