body {
    background-color: #f0f0f0;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
}

.lobby-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #e0e0e0;
}

.lobby-background {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 80%;
    height: 60%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    background: linear-gradient(135deg, #f7f7f7, #e0e0e0);
    border: 1px solid #ccc;
}

.elevator {
    width: 200px;
    height: 300px;
    background: linear-gradient(135deg, #2b2b2b, #1a1a1a);
    border: 2px solid #000;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    cursor: pointer;
    margin-left: 70px;
}

.elevator-doors {
    display: flex;
    width: 100%;
    height: 80%;
}

.door-left, .door-right {
    width: 50%;
    height: 100%;
    background-color: #1a1a1a;
    border: 1px solid #000;
}

.door-left {
    border-right: none;
}

.floor-indicator {
    background: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    margin-bottom: 5px;
    font-size: 20px;
    position: absolute;
    top: 5px;
}

.call-button {
    width: 30px;
    height: 30px;
    background-color: #808080;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #000;
    position: absolute;
    right: -40px;
    top: 100px; /* Moved up */
    display: flex;
    align-items: center;
    justify-content: center;
}

.call-button::before, .call-button::after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
}

.call-button::before {
    transform: rotate(45deg);
    bottom: 5px;
}

.call-button::after {
    transform: rotate(-45deg);
    top: 5px;
}

.receptionist {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-top: 20px; /* Move the entire receptionist component down */
}

.reception-desk {
    width: 300px;
    height: 150px;
    background: linear-gradient(135deg, #555, #333);
    border: 2px solid #000;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 30px; /* Move desk down */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.receptionist-person {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: -50px; /* Adjusted to move head down */
}

.receptionist-head {
    width: 50px;
    height: 50px;
    background: linear-gradient(135deg, #ffcc99, #ff9966);
    border-radius: 50%;
    position: relative;
}

.receptionist-hair {
    width: 50px;
    height: 15px;
    background: #4b2e83;
    border-radius: 50% 50% 0 0;
    position: absolute;
    top: 0;
    left: 0;
}

.receptionist-eyes {
    width: 6px;
    height: 6px;
    background: #000;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 25%;
    margin-left: -3px;
}

.receptionist-eyes::after {
    content: '';
    width: 6px;
    height: 6px;
    background: #000;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-left: 3px;
}

.receptionist-mouth {
    width: 10px;
    height: 6px;
    background: #d24d57;
    border-radius: 0 0 50% 50%;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.receptionist-body {
    width: 50px;
    height: 60px;
    background: #ff9966;
    border-radius: 0 0 50% 50%;
    position: relative;
    top: -20px;
    z-index: -1;
}

.talking .receptionist-mouth {
    width: 10px;
    height: 10px;
    background: #d24d57;
    border-radius: 50%;
    bottom: 0px;
}

.receptionist-message {
    display: none; /* Initially hidden */
    position: absolute;
    top: -200px; /* Move higher above the head */
    left: -50px; /* Adjusted to center above the head */
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 0;
}

.receptionist-message::after {
    content: '';
    position: absolute;
    top: 100%; /* Position the point at the bottom of the bubble */
    left: 50%; /* Center the point horizontally */
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: #fff transparent transparent transparent; /* Adjust colors */
}

.receptionist-message.show {
    display: block; /* Show when the class is added */
}

.exit-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 70px;
}

.door-container {
    position: relative;
    width: 125px;
    height: 250px;
}

.door-container .door-background {
    width: 99%;
    height: 99%;
    background-color: #ccc;
    position: absolute;
    z-index: 0;
    box-sizing: border-box;
}

.door-container .door {
    width: 100%;
    height: 100%;
    background-color: #8B4513;
    border: 2px solid #654321;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    transform: skewY(0deg);
    transform-origin: left center;
    transition: transform 0.3s;
    z-index: 0;
    box-sizing: border-box;
}

.door::before {
    content: '';
    width: 20px;
    height: 20px;
    background-color: #DAA520;
    position: absolute;
    top: 50%;
    left: 85%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.exit-button:hover .door {
    transform: skewY(20deg);
}

.door-container .door-text {
    display: none;
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    color: #000000;
    font-size: 14px;
    background-color: rgba(128, 128, 128, 0.8);
    padding: 5px 10px;
    border-radius: 5px;
}

.exit-button:hover .door-text {
    display: block;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
}

ul {
    list-style: none;
    padding: 0;
}

ul li {
    padding: 10px;
    cursor: pointer;
    transition: background 0.3s;
}

ul li:hover {
    background: #f0f0f0;
}

@media (max-width: 768px) {
    .lobby-background {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 10px;
    }

    .elevator, .receptionist, .exit-button {
        width: 30%;
        height: auto;
        margin: 0;
    }

    .elevator {
        height: 150px;
    }

    .reception-desk {
        width: 100%;
        height: 100px;
        margin-bottom: 5px; /* Move desk down */
    }

    .receptionist-person {
        top: -60px; /* Adjusted to move head down */
    }

    .door-container {
        width: 80px;
        height: 160px;
    }

    .call-button {
        top: 50px; /* Moved up */
    }

    .receptionist {
        margin-top: 350px; /* Move the entire receptionist component down */
    }
}
