body {
  background-color: #1e1e1e;
  color: #c7c7c7;
  font-family: 'Arial', sans-serif;
  margin: 0; /* Ensure there's no default margin */
}

.project-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  background-color: #2e2e2e;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0); /* Ensure sidebar is visible by default */
  position: fixed;
  height: 100%;
  z-index: 1000; /* Ensure sidebar stays on top */
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar.collapsed {
  display: none;
}

.sidebar h2 {
  color: #ffffff;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.nav-links {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.nav-links li {
  margin-bottom: 10px;
  background-color: #444;
  color: #c7c7c7;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.nav-links li:hover {
  background-color: #555;
  color: #ffffff;
}

.nav-links li.visited {
  background-color: #4caf50;
  color: #ffffff;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  margin-left: 280px; /* Adjust for the fixed sidebar */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.main-content.with-sidebar {
  margin-left: 0; /* Remove margin when sidebar is open in mobile view */
}

.main-content h1, .main-content h2 {
  color: #ffffff;
}

.section-container {
  background-color: #3a3a3a;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.image-container {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.image-container img {
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  position: relative;
}

.door-container {
  position: relative;
  width: 90px;
  height: 180px;
}

.door-container .door-background {
  width: 89%;
  height: 89%;
  background-color: #ccc;
  position: absolute;
  z-index: 0;
  box-sizing: border-box;
}

.door {
  width: 100%;
  height: 100%;
  background-color: #8B4513;
  border: 2px solid #654321;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  transform: skewY(0deg);
  transform-origin: left center;
  transition: transform 0.3s;
  z-index: 1;
  box-sizing: border-box;
}

.door::before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: #DAA520;
  position: absolute;
  top: 45%;
  left: 80%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.back-button:hover .door {
  transform: skewY(20deg);
}

.door-container .door-text {
  display: none;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  color: #000000;
  font-size: 14px;
  background-color: rgba(128, 128, 128, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
}

.back-button:hover .door-text {
  display: block;
}

.elevator-overlay-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.small-elevator {
  width: 50px;
  height: 100px;
  background: linear-gradient(135deg, #2b2b2b, #1a1a1a);
  border: 2px solid #000;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;
}

.small-elevator .floor-indicator {
  background: #333;
  color: #fff;
  padding: 2px 5px;
  border-radius: 3px;
  margin-bottom: 2px;
  font-size: 12px;
  position: absolute;
  top: 2px;
}

.small-elevator .elevator-doors {
  display: flex;
  width: 100%;
  height: 80%;
}

.small-elevator .door-left, .small-elevator .door-right {
  width: 50%;
  height: 100%;
  background-color: #1a1a1a;
  border: 1px solid #000;
}

.small-elevator .door-left {
  border-right: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}

.elevator-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.elevator-button {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.button {
  width: 40px;
  height: 40px;
  background-color: silver;
  border-radius: 50%;
  border: 2px solid #999;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.button:active {
  box-shadow: 0 0 10px #fff;
}

.button span {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}

.project-link {
  font-size: 18px;
  color: #333;
  text-decoration: none;
}

.project-link:hover {
  text-decoration: underline;
}

.xp-popup {
  font-size: 24px;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s;
}

.sidebar.open {
  transform: translateX(0) !important;
}

@media (max-width: 768px) {
  .collapse-button {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .collapse-button .door-container {
    width: 50px;
    height: 100px;
  }

  .sidebar.collapsed {
    display: none;
  }

  .project-container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #2e2e2e;
    z-index: 1000;
    transform: translateY(100%); /* Initially hide the sidebar in mobile view */
  }

  .sidebar.open {
    transform: translateY(0); /* Slide the sidebar into view when open in mobile view */
  }

  .main-content {
    padding: 1px;
    margin-bottom: 100px;
    flex-grow: 1;
    margin-left: 0; /* Ensure main content takes full width when sidebar is closed in mobile view */
  }

  .main-content.with-sidebar {
    margin-bottom: 150px; /* Adjust space for the fixed sidebar when open in mobile view */
  }

  .elevator-overlay-button {
    bottom: 80px;
  }
}

@media (min-width: 769px) {
  .collapse-button {
    display: none;
  }

  .project-container {
    display: flex;
    height: 100vh;
  }

  .main-content {
    overflow-y: auto;
    margin-left: 280px; /* Adjust for the fixed sidebar and add padding */
  }
}
