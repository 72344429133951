html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  background-color: #1e1e1e;
  color: #c7c7c7;
  font-family: 'Arial', sans-serif;
}

.project-container {
  display: flex;
  height: 100vh;
}

#threejs-container {
  width: 100vw;
  height: 100vh;
  position: relative;
}

/* Reset Camera Button styling */
#threejs-container .button {
  background-color: #444444;
  border: none;
  color: #ffffff;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.3s;
  white-space: nowrap; /* Prevent text from wrapping */
  min-width: 150px; /* Ensure enough width for text */
  min-height: 40px; /* Ensure enough height for text */
  display: flex; /* Center text horizontally and vertically */
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
}

#threejs-container .button:hover {
  background-color: #555555;
  transform: scale(1.05);
}

/* Positioning for the reset camera button */
.reset-camera-container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
}


/* Ensure the button has enough space for the text */
.reset-camera-container .button {
  background-color: #444444;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  white-space: nowrap; /* Prevent text from wrapping */
}

.reset-camera-container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  display: flex; /* Ensure container adjusts to button size */
  align-items: center; /* Center the button vertically */
  justify-content: center; /* Center the button horizontally */
}




.info-message {
  background-color: #444444;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
  position: absolute;
  top: 10px;
  right: 10px; /* Adjust position from right edge */
  font-size: 16px; /* Increase font size */
  z-index: 10;
  transition: opacity 0.3s;
  opacity: 0.8;
  display: inline-block; /* Ensure it dynamically resizes */
  white-space: nowrap; /* Prevent text from wrapping */
}

.info-message:hover {
  opacity: 1;
}

/* Positioning for the info message */
.instructions-container {
  position: absolute;
  top: 10px;
  right: 10px; /* Ensure it is positioned from the right */
  z-index: 10;
}

.sidebar {
  width: 250px;
  background-color: #2e2e2e;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar h2 {
  color: #ffffff;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  color: #c7c7c7;
  text-decoration: none;
  transition: color 0.3s;
}

.sidebar ul li a:hover {
  color: #ffffff;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}

.main-content h1, .main-content h2 {
  color: #ffffff;
}

.main-content section {
  margin-bottom: 20px;
}

.image-container {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.image-container img {
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 20px;
}

.plant-container {
  width: 40px; /* Adjusted width */
  height: 80px; /* Adjusted height */
  position: relative;
  margin-right: 20px;
}

.pot {
  width: 30px;
  height: 30px;
  background-color: #B22222; /* Clay red color */
  border-radius: 15px 15px 0 0; /* Rounded top edges */
  position: absolute;
  bottom: 0;
}

.plant {
  width: 50px;
  height: 50px;
  background-color: #228B22; /* Leafy green color */
  border-radius: 50%;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.door-background {
  width: 80px; /* Larger size */
  height: 160px; /* Larger size */
  background-color: #ccc; /* Grey color */
  position: absolute;
  z-index: -1;
}

.door {
  width: 80px; /* Larger size */
  height: 160px; /* Larger size */
  background-color: #8B4513; /* Brown color */
  border: 2px solid #654321; /* Darker brown border */
  border-radius: 3px;
  position: relative;
  transform: skewY(0deg); /* Default closed door */
  transform-origin: left center;
  transition: transform 0.3s;
}

.door::before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: #DAA520; /* Brass/yellow handle */
  position: absolute;
  top: 45%; /* Slightly farther down */
  left: 80%; /* Slightly to the right */
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.back-button:hover .door {
  transform: skewY(20deg); /* Parallelogram effect to show door is open downwards */
}

.door-text {
  display: none;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  color: #c7c7c7;
  font-size: 14px;
}

.back-button:hover .door-text {
  display: block;
}
